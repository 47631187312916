<template>
  <div>
    <carousel
      loop
      navigationEnabled
      :paginationEnabled="slidesImages ? false : true"
      paginationPosition="bottom-overlay"
      :perPageCustom="slidesPerPage"
      :navigationClickTargetSize="10"
      navigationNextLabel='<svg class="navigationNextLabel" width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.5 11L14.5 21.6694L25.5 32" stroke="#6584F1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>'
      navigationPrevLabel='<svg class="navigationPrevLabel" width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.5 11L14.5 21.6694L25.5 32" stroke="#6584F1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>'
      paginationColor="#6584f180"
      paginationActiveColor="#6584F1"
    >
      <template v-if="slidesImages">
        <slide v-for="(image, imageIndex) in slidesImages[0]" :key="imageIndex">
          <img :src="image" @click="showMultiple(imageIndex)" />
        </slide>
      </template>
      <template v-else>
        <slide v-for="(card, cardIndex) in cards" :key="cardIndex">
          <div class="benefit">
            <div class="benefit-heading db1 mb2">
              <img src="@/assets/icons/done.svg" class="benefit-img dr3 mr2" />
              <p class="benefit-title">{{ card.title }}</p>
            </div>
            <p class="benefit-text">{{ card.text }}</p>
          </div>
        </slide>
      </template>
    </carousel>

    <vue-easy-lightbox
      v-if="slidesImages"
      escDisabled
      moveDisabled
      loop
      :visible="visible"
      :imgs="slidesImages[1] ? slidesImages[1] : slidesImages[0]"
      :index="index"
      @hide="handleHide"
    >
    </vue-easy-lightbox>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: "About",
  props: {
    slidesPerPage: {
      type: Array,
      default() {
        return [[320, 1]];
      },
    },
    slidesImages: {
      type: Array,
    },
    cards: {
      type: Array,
    },
  },
  components: {
    Carousel,
    Slide,
    VueEasyLightbox,
  },
  data() {
    return {
      visible: false,
      index: 0,
    };
  },
  methods: {
    showMultiple(idx) {
      this.index = idx;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss">
@import "../styles/_variables.scss";
@import "../styles/_mixins.scss";
@import "../styles/_functions.scss";

.VueCarousel-navigation {
  &-next {
    svg {
      transform: rotate(180deg);
    }
  }

  button {
    svg {
      border-radius: 100%;
      transition: background $fx;

      &:hover {
        background: $blue-purple-disabled;
      }
    }
  }

  @include mobile {
    display: none;
  }
}

.benefit-slider {
  .VueCarousel {
    box-sizing: border-box;
    max-width: rem(345px);
    padding: rem(space(7));
    box-shadow: $shadow-lg;
    border-radius: $border-radius;

    &-slide {
      display: flex;
      align-items: center;
    }

    @include mobile {
      padding: rem(space(6));
    }

    &-pagination--bottom-overlay {
      bottom: rem(space(8) * -1) !important;
      left: 0;
      right: 0;
    }
  }
}
</style>
